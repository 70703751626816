import {Controller} from "stimulus"

import "select2/dist/css/select2"
import "select2-bootstrap-theme/dist/select2-bootstrap"

export default class extends Controller {
    connect() {
        $('select.beer-form-breweries').select2({
            placeholder: 'Search for breweries',
            ajax: {
                url: $('.beer-form-breweries').data('endpoint'),
                dataType: 'json',
                data(params) {
                    return {query: params.term}
                },
                processResults(data) {
                    return {
                        results: $.map(data, item => ({
                                text: item.name,
                                id: item.id
                            })
                        )
                    };
                }
            }
        });

        $('select.beer-form-style').select2({
            placeholder: 'Search for a style',
            ajax: {
                url: $('.beer-form-style').data('endpoint'),
                dataType: 'json',
                data(params) {
                    return {query: params.term}
                },
                processResults(data) {
                    return {
                        results: $.map(data, item => ({
                                text: item.name,
                                id: item.id
                            })
                        )
                    };
                }
            }
        });

        $('select.beer-form-tavour-product').select2({
            placeholder: 'Link Tavour product'
        });

        $('select.beer-form-tasting-keywords').select2({
            placeholder: 'Search for tasting keywords',
            tags: true,
            createTag: function (tag) {
                return {
                    id: tag.term,
                    text: tag.term,
                    tag: true
                };
            },
            ajax: {
                url: $('.beer-form-tasting-keywords').data('endpoint'),
                dataType: 'json',
                data(params) {
                    return {query: params.term}
                },
                processResults(data) {
                    return {
                        results: $.map(data, item => ({
                                text: item.name,
                                id: item.id
                            })
                        )
                    };
                },
            },
        }).on("select2:select", function (e) {
            if (e.params.data.tag == true) {
                var tag = e.params.data.text
                var newTag = $(this).find('[data-select2-tag="true"]');
                $.ajax({
                    type: "POST",
                    dataType: 'json',
                    url: $('.beer-form-tasting-keywords').data('endpoint'),
                    data: {
                        tasting_keyword: {
                            name: tag,
                            type: "TastingKeyword"
                        }
                    },
                    success: function (res) {
                        newTag.replaceWith('<option selected value="' + res.id + '">' + res.name + '</option>');
                    }
                });
            }
        });

        $('select.beer-form-ingredients').select2({
            placeholder: 'Search for ingredients',
            tags: true,
            createTag: function (tag) {
                return {
                    id: tag.term,
                    text: tag.term,
                    tag: true
                };
            },
            ajax: {
                url: $('.beer-form-ingredients').data('endpoint'),
                dataType: 'json',
                data(params) {
                    return {query: params.term}
                },
                processResults(data) {
                    return {
                        results: $.map(data, item => ({
                                text: item.name,
                                id: item.id
                            })
                        )
                    };
                }
            }
        }).on("select2:select", function (e) {
            if (e.params.data.tag == true) {
                var tag = e.params.data.text
                var newTag = $(this).find('[data-select2-tag="true"]');
                $.ajax({
                    type: "POST",
                    dataType: 'json',
                    url: $('.beer-form-ingredients').data('endpoint'),
                    data: {
                        ingredient: {
                            name: tag,
                            type: "Ingredient"
                        }
                    },
                    success: function (res) {
                        newTag.replaceWith('<option selected value="' + res.id + '">' + res.name + '</option>');
                    }
                });
            }
        });
    }
}