import { Controller } from "stimulus"

import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css';

export default class extends Controller {
    connect() {
        $('[data-editor="summernote"]').each(function(i, obj) {
            $(obj).summernote({
                height: 150,
                width: 500,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'paragraph']],
                ],
                tooltip: false,
                tabDisable: true,
                callbacks: {
                    onPaste(e) {
                        const bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    },
                    onChange(e) {
                        if( $(obj).summernote('isEmpty') ) {
                            $(obj).next('.note-editor').find('.note-editable').html('')
                        }
                    }
                }
            })
        })
    }
}